import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/hidden.png'
import _imports_1 from '@/assets/img/skip.png'


const _hoisted_1 = { class: "modole" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body_content" }
const _hoisted_5 = { class: "moneytitle" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "money_join" }
const _hoisted_8 = { class: "pay_method" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "payment" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "pay" }
const _hoisted_13 = { class: "pay_money" }
const _hoisted_14 = { class: "meet" }
const _hoisted_15 = { class: "btn_text" }
const _hoisted_16 = {
  key: 1,
  class: "fork"
}
const _hoisted_17 = { class: "agreement" }

import { ref } from 'vue';
import { lang } from '@/lang';
import WalletPayPopup from '@/components/WalletPayPopup/index.vue';
import BankPayPopup from '@/components/BankPayPopup/index.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    payCard: {},
    Refresh: { type: Function }
  },
  setup(__props: any, { expose: __expose }) {

const show = ref<boolean>(false);
const keyword = ref<number>();
const loading = ref<boolean>(false);
const props = __props;
const walletPayPopup = ref<any>(null);
const bankPayPopup = ref<any>(null);
// 翻译文案
const WALLET = lang.v.WALLET;
const THIRD_PAY = lang.v.THIRD_PAY;
// 处理提交事件
const handleConfirm = () => {
  if (!walletPayPopup.value) return;
  if (payment.value === '0') {
    walletPayPopup.value?.open();
  } else if (payment.value === '1') {
    bankPayPopup.value?.open();
  }
};
const pays = [
  {
    id: '0',
    platformType: 'WALLET',
    name: WALLET,
  },
  {
    id: '1',
    platformType: 'THIRD_PARTY',
    name: THIRD_PAY,
  },
];

// 支付方式类型
let payment = ref<string>('0');

//选择支付方式
const check_pay = (id: string) => {
  payment.value = id;
};
// 处理取消事件
const handleCancel = () => {
  show.value = false;
};
const open = () => {
  show.value = true;
};

__expose({ open });

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_van_loading = _resolveComponent("van-loading")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_popup, {
      show: show.value,
      class: "popup_box"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "stick" }, null, -1)),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(lang).v.crowdfunding), 1)
            ]),
            _createElementVNode("div", {
              class: "block_top_right",
              onClick: handleCancel
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "close_icon"
              }, null, -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(lang).v.Participation), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_unref(lang).v.PleaseAmount), 1),
              _createVNode(_component_el_select, {
                modelValue: keyword.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((keyword).value = $event)),
                placeholder: "Select",
                size: "default",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.payCard?.intervalMoneys, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item,
                      label: item,
                      value: item
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(lang).v.method), 1),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(pays, (pay) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(['box', _unref(payment) == pay.id ? 'box_active' : '']),
                    key: pay.id,
                    onClick: ($event: any) => (check_pay(pay.id))
                  }, _toDisplayString(pay.name), 11, _hoisted_11)
                }), 64))
              ]),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(lang).v.Payamount), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(keyword.value ? _unref(lang).v.unit + keyword.value : ''), 1),
              _createElementVNode("div", _hoisted_14, [
                (!loading.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "fork",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (handleConfirm()))
                    }, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "skip" }, [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: ""
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(payment) === '1' ? _unref(lang).v.jump : _unref(lang).v.gopay), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_van_loading, {
                        type: "spinner",
                        size: "0.24rem",
                        color: "#FFFFFF"
                      })
                    ])),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("p", null, _toDisplayString(_unref(lang).v.PaymentAgreement), 1),
                  _createElementVNode("p", null, _toDisplayString(_unref(lang).v.DramaAgreement), 1)
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(WalletPayPopup, {
      ref_key: "walletPayPopup",
      ref: walletPayPopup,
      payMoney: keyword.value,
      CardId: props.payCard?.id,
      Refresh: props.Refresh
    }, null, 8, ["payMoney", "CardId", "Refresh"]),
    _createVNode(BankPayPopup, {
      ref_key: "bankPayPopup",
      ref: bankPayPopup,
      Refresh: props.Refresh,
      payMoney: keyword.value,
      CardId: props.payCard?.id
    }, null, 8, ["Refresh", "payMoney", "CardId"])
  ], 64))
}
}

})