import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wallet_block" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "tips" }
const _hoisted_5 = { class: "input_container" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "pay_info" }
const _hoisted_8 = { class: "dialog_footer" }

import { lang } from '@/lang';
import { ref } from 'vue';
import message from '@/utils/message';
import { charge, fetchCode } from '@/api/fetcher';
import CountDown from '../CountDown';
import dataCenter from '@/dataCenter';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    payMoney: {},
    CardId: {},
    Refresh: { type: Function }
  },
  setup(__props: any, { expose: __expose }) {

const show = ref<boolean>(false);
const loading = ref<boolean>(false);
const props = __props;
// 验证码相关
const text = ref(lang.v.getCode);
const countDown = new CountDown();
const count = ref<number>(0);
async function onGetCode() {
  if (count.value) {
    return;
  }
  if (dataCenter.user.data) {
    fetchCode(dataCenter.user.data.email).then(resp => {
      if (!resp) {
        return;
      }
      countDown.start(90, value => {
        count.value = value;
        if (value) {
          text.value = `${value}S`;
        } else {
          text.value = lang.v.getCode;
        }
      });
    });
  }
}
//form参数
const captcha = ref('');
const tips = ref('');
const open = () => {
  if (props.payMoney) {
    show.value = true;
  }
};

// 取消
const payCancel = () => {
  const resetValues = [captcha, tips];
  resetValues.forEach(value => {
    value.value = '';
  });
  show.value = false;
};
// 提交
const paySubmit = () => {
  if (!captcha.value) {
    return message.error(lang.v.pwdNotNull);
  }
  loading.value = true;
  charge({
    id: props.CardId,
    rechargeType: 'INVESTMENT',
    payType: 'H5',
    platformType: 'WALLET',
    captcha: captcha.value,
    investmentMoney: props.payMoney,
  })
    .then(() => {
      message.success('success');
      show.value = false;
      props.Refresh();
      captcha.value = '';
    })
    .finally(() => {
      loading.value = false;
    });
};

__expose({ open });

return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: show.value,
    class: "popup_box",
    "close-on-click-overlay": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(lang).v.Payment), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(lang).v.will) + " " + _toDisplayString(_unref(dataCenter).user.data?.email) + _toDisplayString(_unref(lang).v.setCode), 1),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              placeholder: _unref(lang).v.enterCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((captcha).value = $event))
            }, null, 8, _hoisted_6), [
              [_vModelText, captcha.value]
            ]),
            _createElementVNode("span", {
              class: "coder",
              onClick: onGetCode
            }, _toDisplayString(text.value), 1)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(tips.value), 1)
        ]),
        _createElementVNode("span", _hoisted_8, [
          _createElementVNode("button", {
            onClick: payCancel,
            class: "cancel",
            style: {"border-radius":"0.15rem","width":"0.8rem"}
          }, _toDisplayString(_unref(lang).v.cancel), 1),
          _createElementVNode("button", {
            onClick: paySubmit,
            class: "submit",
            style: {"margin-left":"0.2rem","background-color":"#ed931e","color":"#eee","border-radius":"0.15rem","width":"2.1rem"}
          }, _toDisplayString(_unref(lang).v.confirmPay.replace('$y', String(props?.payMoney))), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})