import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-box" }
const _hoisted_2 = ["placeholder"]

import { ref, reactive, onMounted } from 'vue';
import { lang } from '@/lang';
import message from '@/utils/message';
import { invitebind, inviteInfo } from '@/api/fetcher';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {

const warning1 = lang.v.warning1;
const success1 = lang.v.success1;
// 显示邀请码弹窗
const showInvitaCode = ref(false);
// 邀请代码
const addcode = reactive({
  invitationCode: '',
});
// 用户绑定的邀请码
const parentInvitationCode = ref('');
// 提交用户邀请码
const confirm = async () => {
  // 如果输入框的数据成功绑定，则显示出来，如果没有，则提示错误
  if (addcode.invitationCode == '') {
    message.error(warning1);
  } else {
    await invitebind(addcode).then(async res => {
      if (res) {
        message.success('success1');
        showInvitaCode.value = false;
        await inviteInfo().then(res => {
          parentInvitationCode.value = res?.parentInvitationCode || '';
        });
      } else {
        parentInvitationCode.value = '';
        return;
      }
    });
  }
};
//邀请码弹窗关闭事件
const onCloseCodeDialog = () => {
  showInvitaCode.value = false;
  addcode.invitationCode = '';
};

const InvitationCode = () => {
  return !parentInvitationCode.value;
};

// 打开页面
const openInvitaCode = () => {
  showInvitaCode.value = true;
};
onMounted(async () => {
  // 获取用户邀请相关信息
  await inviteInfo().then(res => {
    parentInvitationCode.value = res?.parentInvitationCode || '';
  });
});

__expose({ InvitationCode, openInvitaCode });

return (_ctx: any,_cache: any) => {
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createBlock(_component_van_dialog, {
    "zIndex:10": "",
    show: showInvitaCode.value,
    title: _unref(lang).v.Fill,
    onClose: onCloseCodeDialog,
    onConfirm: confirm,
    closeOnClickOverlay: "",
    "confirm-button-text": _unref(lang).v.confirm,
    "confirm-button-color": "rgba(237, 147, 30, 1)"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addcode.invitationCode) = $event)),
          placeholder: _unref(lang).v.pleaEnterInviteCode
        }, null, 8, _hoisted_2), [
          [_vModelText, addcode.invitationCode]
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "title", "confirm-button-text"]))
}
}

})