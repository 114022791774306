import { lang } from '@/lang';
const ends = lang.v.ends;
export const updateCountdown = (endTime: string) => {
  const end = new Date(endTime).getTime();
  const now = new Date().getTime();
  const difference = end - now;
  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days}d${hours}h${minutes}m${seconds}s`;
  } else {
    return ends;
  }
};
