import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"center","padding-left":"0.14rem","padding-right":"0.14rem"} }
const _hoisted_3 = { class: "channels" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "payment_form" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "checkbox_container" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "tips" }
const _hoisted_12 = { class: "input_container" }
const _hoisted_13 = { class: "pay_info" }
const _hoisted_14 = { class: "pay_info" }

import { charge, fetchCode, platform } from '@/api/fetcher';
import { lang } from '@/lang';
import { ref, reactive, watch } from 'vue';
import CountDown from '../CountDown';
import { PayPlatformResp } from '@/api/protocol';
import dataCenter from '@/dataCenter';
import message from '@/utils/message';
import router from '@/router';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    payMoney: {},
    CardId: {},
    Refresh: { type: Function }
  },
  setup(__props: any, { expose: __expose }) {

const captcha = ref('');
const tips = ref('');
const loading = ref(false);
const payInfo = reactive<PayPlatformResp[]>([]);
const props = __props;
const show = ref(false);
// open
const open = () => {
  if (props.payMoney) {
    show.value = true;
    getPayChannels();
    state.bill = props.payMoney;
    state.wallet = Number(dataCenter.user.data?.balance);
  }
};
// 混合支付相关
const useMixPay = ref(false);
const onCheckbox = () => {
  useMixPay.value = !useMixPay.value;
  state.useMixPay = useMixPay.value;
};

//选择支付渠道相关
const selectChannel = ref<PayPlatformResp>();

// 请求支付通道
const getPayChannels = async () => {
  try {
    const data = await platform({
      amount: String(props.payMoney),
    });
    if (data) {
      Object.assign(payInfo, data);
      selectChannel.value = data[0];
      state.minThirdPay = data[0].minAmount;
    }
  } catch {
    return;
  }
};
// 金额处理相关

const state = reactive({
  useMixPay: false,
  coupon: 0,
  minThirdPay: 0,
  wallet: 0,
  bill: 0,
});
const pay_info = reactive({ wallet: 0, thirdPay: 0 });

watch(
  state,
  () => {
    if (state.useMixPay) {
      if (state.wallet >= state.bill) {
        pay_info.wallet = state.bill - state.coupon - state.minThirdPay;
        pay_info.thirdPay = state.minThirdPay;
      } else {
        const diff = state.bill - state.wallet - state.coupon;
        if (diff >= state.minThirdPay) {
          pay_info.thirdPay = diff;
          pay_info.wallet = state.bill - state.coupon - diff;
        } else {
          pay_info.thirdPay = state.minThirdPay;
          pay_info.wallet = state.bill - state.coupon - state.minThirdPay;
        }
      }
    } else {
      const diff = state.bill - state.coupon;
      if (diff >= state.minThirdPay) {
        pay_info.thirdPay = diff;
      } else {
        pay_info.thirdPay = state.minThirdPay;
      }
      pay_info.wallet = 0;
    }
  },
  { deep: true }
);

// 验证码相关
const text = ref(lang.v.getCode);
const countDown = new CountDown();
const count = ref<number>(0);
async function onGetCode() {
  if (count.value) {
    return;
  }
  if (dataCenter.user.data) {
    fetchCode(dataCenter.user.data.email).then(resp => {
      if (!resp) {
        return;
      }
      countDown.start(90, value => {
        count.value = value;
        if (value) {
          text.value = `${value}S`;
        } else {
          text.value = lang.v.getCode;
        }
      });
    });
  }
}
// 取消
const payCancel = () => {
  const resetValues = [captcha, tips];
  resetValues.forEach(value => {
    value.value = '';
  });
  show.value = false;
};

//确定
const paySubmit = async () => {
  if (useMixPay.value && !captcha.value) {
    return message.error(lang.v.pwdNotNull);
  }
  loading.value = true;
  if (selectChannel.value) {
    console.log(selectChannel.value);

    charge({
      id: props.CardId,
      rechargeType: 'INVESTMENT',
      payType: 'H5',
      platformType: 'THIRD_PARTY',
      investmentMoney: pay_info.thirdPay,
      comboPay: useMixPay.value,
      thirdPartyPayPlatformId: selectChannel.value.id,
      captcha: captcha.value || null,
      extra: {},
    })
      .then(() => {
        message.success(lang.v.success1);
        captcha.value = '';
        router.push('/paystatus');
      })
      .finally(() => {
        loading.value = false;
      });
  }
};
// 选择支付渠道
const onSelectChannel = (item: PayPlatformResp) => {
  if (selectChannel.value === item) {
    return;
  }
  selectChannel.value = item;
  state.minThirdPay = item.minAmount;
};

__expose({ open });

return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: show.value,
    class: "popup_box",
    onClickOverlay: payCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(payInfo, (item) => {
              return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["item", {
              active: item.id === selectChannel.value?.id,
              color_text: item.id != selectChannel.value?.id,
            }]),
                onClick: ($event: any) => (onSelectChannel(item)),
                key: item.id
              }, [
                _withDirectives(_createElementVNode("img", {
                  src: item.icon,
                  class: "logo"
                }, null, 8, _hoisted_5), [
                  [_vShow, item.icon]
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(item.name), 1)
              ], 10, _hoisted_4))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(lang).v.BalanceCombinationPayment), 1),
          _createElementVNode("label", {
            class: "amount_info",
            onClick: _withModifiers(onCheckbox, ["stop"])
          }, [
            _createElementVNode("span", null, _toDisplayString(_unref(lang).v.AvailableBalance) + _toDisplayString(_unref(dataCenter).user.data?.balance), 1),
            _createElementVNode("span", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                checked: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((useMixPay).value = $event)),
                disabled: "true"
              }, null, 512), [
                [_vModelCheckbox, useMixPay.value]
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "checkbox_checkmark" }, null, -1))
            ])
          ]),
          (useMixPay.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(lang).v.will) + " " + _toDisplayString(_unref(dataCenter).user.data?.email) + _toDisplayString(_unref(lang).v.setCode), 1),
                _createElementVNode("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "number",
                    placeholder: "Enter verification code",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((captcha).value = $event))
                  }, null, 512), [
                    [_vModelText, captcha.value]
                  ]),
                  _createElementVNode("span", {
                    class: "coder",
                    onClick: onGetCode
                  }, _toDisplayString(text.value), 1)
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(lang).v.BalanceDeductions.replace('$y', String(pay_info.wallet))), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, _toDisplayString(tips.value), 1),
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "gopay",
            onClick: paySubmit
          }, [
            _createTextVNode(_toDisplayString(_unref(lang).v.goPay.replace('$y', String(pay_info.thirdPay))), 1)
          ])), [
            [_directive_loading, loading.value]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})